import DansukHome from "./components/DansukHome.vue"

import UsefulLinks from "./components/UsefulLinks.vue"

import DocumentsHome from "./components/documents-and-catalogues/DocumentsHome.vue"
import CorporateIdentity from "./components/documents-and-catalogues/CorporateIdentity.vue"
import DocumentsDansukHome from "./components/documents-and-catalogues/DocumentsDansukHome.vue"
import DocumentsDansukAR from "./components/documents-and-catalogues/DocumentsDansukAR.vue"
import DocumentsDansukPresentations from "./components/documents-and-catalogues/DocumentsDansukPres.vue"
import DocumentsDansukCatalogs from "./components/documents-and-catalogues/DocumentsDansukCatalogs.vue"
import DocumentsDansukGeneral from "./components/documents-and-catalogues/DocumentsDansukGeneral.vue"
import DocumentsArcelik from "./components/documents-and-catalogues/DocumentsArcelik.vue"
import DocumentsLGPrice from "./components/documents-and-catalogues/DocumentsLGPrice.vue"
import DocumentsLGAll from "./components/documents-and-catalogues/DocumentsLGAll.vue"
import DocumentsOtherAll from "./components/documents-and-catalogues/DocumentsOtherAll.vue"
import DocumentsHitachi from "./components/documents-and-catalogues/DocumentsHitachi.vue"
import DocumentsTechnical from "./components/documents-and-catalogues/DocumentsTechnical.vue"
import DocumentsLGHome from "./components/documents-and-catalogues/DocumentsLGHome.vue"
import AddDocument from "./components/documents-and-catalogues/AddDocument.vue"

import SignIn from "./components/SignIn.vue"

import TrainingHome from "./components/trainings/TrainingHome.vue"
import DansukTraining from "./components/trainings/DansukTraining.vue"
import TrelloTraining from "./components/trainings/DansukTrello.vue"
import GoogleDriveTraining from "./components/trainings/DansukGoogleDrive.vue"
import DTSTraining from "./components/trainings/DTSTraining.vue"
import UsefulVideos from "./components/trainings/UsefulVideos.vue"
import StandardTrainings from "./components/trainings/StandardTrainings.vue"
import DashboardTraining from "./components/trainings/DashboardTraining.vue"

import DansukDigitalHome from "./components/dansuk-digital/DansukDigitalHome.vue"

import SurveysHome from "./components/surveys/SurveyHome.vue"
import DansukSurveys from "./components/surveys/DansukSurveys.vue"
import OtherSurveys from "./components/surveys/OtherSurveys.vue"
import ForwardSurveyResponse from "./components/surveys/ForwardSurveyResponse.vue"
import SurveyResponses from "./components/surveys/SurveyResponses.vue"

import HumanResourcesHome from "./components/requests/HumanResourcesHome.vue"
import HRDocuments from "./components/requests/HRDocuments.vue"
import HRAddDocument from "./components/requests/HRAddDocument.vue"
import HRAddMessage from "./components/requests/HRAddMessage.vue"

import RequestsHome from "./components/requests/RequestsHome.vue"
import LeaveRequests from "./components/requests/LeaveRequests.vue"
import MeetingRequests from "./components/requests/MeetingRequests.vue"
import DocumentRequests from "./components/requests/DocumentRequests.vue"
import OtherRequests from "./components/requests/OtherRequests.vue"

import OtherRequestsAdmin from "./components/requests/OtherRequestsAdmin.vue"
import LeaveRequestsAdmin from "./components/requests/LeaveRequestsAdmin.vue"
import MeetingRequestsAdmin from "./components/requests/MeetingRequestsAdmin.vue"
import DocumentRequestsAdmin from "./components/requests/DocumentRequestsAdmin.vue"

import SuggestionsHome from "./components/suggestions/SuggestionsHome.vue"
import PlannedChanges from "./components/suggestions/PlannedChanges.vue"
import PanelSuggestions from "./components/suggestions/PanelSuggestions.vue"
import BugReports from "./components/suggestions/BugReports.vue"
import GeneralSuggestions from "./components/suggestions/GeneralSuggestions.vue"
import GeneralSuggestionsAdmin from "./components/suggestions/GeneralSuggestionsAdmin.vue"
import PanelSuggestionsAdmin from "./components/suggestions/PanelSuggestionsAdmin.vue"
import BugReportsAdmin from "./components/suggestions/BugReportsAdmin.vue"

import LinkedInPosts from "./components/dansuk-digital/LinkedInPosts.vue"
import LinkedInPostSuggestions from "./components/dansuk-digital/LinkedInSuggestions.vue"
import IntegratedLinkedInPosts from "./components/dansuk-digital/IntegratedLinkedInPosts.vue"
import ReferenceSubmission from "./components/dansuk-digital/ReferenceSubmission.vue"
import ReferenceAdmin from "./components/dansuk-digital/ReferenceAdmin.vue"
import QRRequest from "./components/dansuk-digital/QRRequest"
import QRRequestAdmin from "./components/dansuk-digital/QRRequestAdmin"

import DansukBMSHome from "./components/dansuk-digital/bms/bmsHome.vue"
import BMSProjectOverview from "./components/dansuk-digital/bms/bmsProjectOverview.vue"
import BMSProjectCompletion from "./components/dansuk-digital/bms/bmsProjectCompletion.vue"
import BMSProjectUpdates from "./components/dansuk-digital/bms/bmsProjectUpdates.vue"
import bmsProjectForm from "./components/dansuk-digital/bms/bmsProjectForm.vue"
import BMSProjectMetaEdit from "./components/dansuk-digital/bms/BMSProjectMetaEdit.vue"

import DSSHome from "./components/dansuk-digital/dss/DSSHome.vue"
import DSSServiceRequests from "./components/dansuk-digital/dss/DSSServiceRequests.vue"
import DSSServiceRecords from "./components/dansuk-digital/dss/DSSServiceRecords.vue"
import DSSAllRecords from "./components/dansuk-digital/dss/DSSAllRecords"
import DSSProjects from "./components/dansuk-digital/dss/DSSProjects.vue"
import DSSAddProject from "./components/dansuk-digital/dss/DSSAddProject.vue"

import GoalsHome from "./components/goals/GoalsHome"
import AllGoals from "./components/goals/AllGoals"
import MaintenanceGoals from "./components/goals/MaintenanceGoals"
import AdminCreateGoal from "./components/goals/AdminCreateGoal"
import GoalsCategoryManager from "./components/goals/GoalsCategoryManager.vue"

import StatsHome from "./components/stats/statsHome.vue"
import FinancialStats from "./components/stats/financialStats.vue"
import FinancialStats2024 from "./components/stats/financialStats-2024.vue"
import FinancialStats2023 from "./components/stats/financialStats-2023.vue"
import FinancialStats2022 from "./components/stats/financialStats-2022.vue"
import YearlyStats from "./components/stats/yearlyStats.vue"
import ProjectStats from "./components/stats//projects/projectStats.vue"

import OnlineMonitoringHome from "./components/online-monitoring/OnlineMonitoringHome.vue"

import ToolsHome from "./components/tools/toolsHome.vue"
import MailSignatureGenerator from "./components/tools/mailSignatureGenerator.vue"

import eventCategories from "./components/calendar/eventCategories.vue"
import accaCalendar from "./components/calendar/accaCalendar.vue"

import CRMHome from "./components/crm/crmHome.vue"
import EMailList from "./components/crm/EMailList.vue"
import EMailTags from "./components/crm/EMailTags.vue"

import omHome from "./components/om-platform/omHome.vue"
import electricityPriceTable from "./components/om-platform/electricityPriceTable.vue"
import omProjects from "./components/om-platform/omProjects.vue"
import omSingleProject from "./components/om-platform/omSingleProject.vue"
import omProjectForm from "./components/om-platform/omProjectForm.vue"

import SuperAdminHome from "./components/superAdmin/SuperAdminHome.vue"


export const routes = [
    {path: "/", name: "DansukHome", components: {
        default: DansukHome, 
    }},
     {path: "/sign-in", name: 'SignIn', components: {default: SignIn}},

    {path: "/useful-links", components: { default: UsefulLinks}},

    {path: "/documents", components: {
        default: DocumentsHome,
        },
    },

    
    {path: "/documents/dansuk", components: { default: DocumentsDansukHome}},
    {path: "/documents/corporate", components: { default: CorporateIdentity}},
    {path: "/documents/dansuk/ar", components: {default: DocumentsDansukAR}},
    {path: "/documents/dansuk/catalogs", components: {default: DocumentsDansukCatalogs}},
    {path: "/documents/dansuk/presentations", components: {default: DocumentsDansukPresentations}},
    {path: "/documents/dansuk/general", components: {default: DocumentsDansukGeneral}},
    {path: "/documents/arcelik", components: {default: DocumentsArcelik}},
    {path: "/documents/lg", components: {default: DocumentsLGHome}},
    {path: "/documents/lg/all", components: {default: DocumentsLGAll}},
    {path: "/documents/lg/price-lists", components: {default: DocumentsLGPrice}},
    {path: "/documents/other-companies", components: {default: DocumentsOtherAll}},
    {path: "/documents/hitachi", components: {default: DocumentsHitachi}},
    {path: "/documents/technical", components: {default: DocumentsTechnical}},
    {path: "/documents/add", components: {default: AddDocument}, meta: {requiresAuth: true}},

    {path: "/training/", components: {default: TrainingHome}},
    {path: "/training/dansuk", components: {default: DansukTraining}},
    {path: "/training/useful-videos", components: {default: UsefulVideos}},
    {path: "/training/dansuk/trello", components: {default: TrelloTraining}},
    {path: "/training/dansuk/google-drive", components: {default: GoogleDriveTraining}},
    {path: "/training/dansuk/dts", components: {default: DTSTraining}},
    {path: "/training/dansuk/dash", components: {default: DashboardTraining}},

    {path: "/training/standard-trainings", components: {default: StandardTrainings}},


    // Paths for Digital:

    {path: "/digital/", components: {default: DansukDigitalHome}},
    {path: "/digital/linkedin-suggestions", components: {default: LinkedInPostSuggestions}},
    {path: "/digital/linkedin-schedule", components: {default: IntegratedLinkedInPosts}},
    {path: "/digital/reference-submission", components: {default: ReferenceSubmission}},
    {path: "/digital/qr-request", components: {default: QRRequest}},

    {path: "/digital/bms/home", components: {default: DansukBMSHome}},
    {path: "/digital/bms/project", components: {default: BMSProjectOverview}},
    {path: "/digital/bms/project/updates", components: {default: BMSProjectUpdates}},
    {path: "/digital/bms/project/completion", components: {default: BMSProjectCompletion}},
    {path: "/digital/bms/project/edit-project", components: {default: BMSProjectMetaEdit}},
    {path: "/digital/bms/add-project", components: {default: bmsProjectForm}},

    {path: "/digital/dss/", components: {default: DSSHome}},
    {path: "/digital/dss/service-requests", components: {default: DSSServiceRequests}},
    {path: "/digital/dss/service-records", components: {default: DSSServiceRecords}},
    {path: "/digital/dss/all", components: {default: DSSAllRecords}},
    {path: "/digital/dss/projects", components: {default: DSSProjects}},
    {path: "/digital/dss/add-project", components: {default: DSSAddProject}},


    // Paths for Digital Admin:
        
    {path: "/digital/integrated-linkedin-posts", components: {default: IntegratedLinkedInPosts}, meta: {requiresAuth: true}},
    {path: "/digital/linkedin-posts", components: {default: LinkedInPosts}, meta: {requiresAuth: true}},
    {path: "/digital/reference-admin", components: {default: ReferenceAdmin}, meta: {requiresAuth: true}},
    {path: "/digital/qr-admin", components: {default: QRRequestAdmin}, meta: {requiresAuth: true}},

        // Paths for Surveys: 

    {path: "/surveys/", components: {default: SurveysHome}},
    {path: "/surveys/dansuk", components: {default: DansukSurveys}},
    {path: "/surveys/outbound", components: {default: OtherSurveys}},
    {path: "/surveys/outbound/responses", components: {default: SurveyResponses}},

        // Paths for Survey Admin:

    {path: "/surveys/forward-response", components: {default: ForwardSurveyResponse}},

    // Paths for HR: (HR and Requests are together.)

    {path: "/human-resources", components: {default: HumanResourcesHome}},
    {path: "/human-resources/documents", components: {default: HRDocuments}},

        // Paths for HR Admin

    {path: "/human-resources/documents/add", components: {default: HRAddDocument}},
    {path: "/human-resources/messages/add", components: {default: HRAddMessage}},

    // Paths for requests: 

    {path: "/requests/", components: {default: RequestsHome}},
    {path: "/requests/leave", components: {default: LeaveRequests}},
    {path: "/requests/meeting", components: {default: MeetingRequests}},
    {path: "/requests/document", components: {default: DocumentRequests}},
    {path: "/requests/other", components: {default: OtherRequests}},

        // Paths for requests admins: 
    
    {path: "/requests/admin/leave", components: {default: LeaveRequestsAdmin}, meta: {requiresAuth: true}},
    {path: "/requests/admin/meeting", components: {default: MeetingRequestsAdmin}, meta: {requiresAuth: true}},
    {path: "/requests/admin/document", components: {default: DocumentRequestsAdmin}, meta: {requiresAuth: true}},
    {path: "/requests/admin/other", components: {default: OtherRequestsAdmin}, meta: {requiresAuth: true}},

    // Paths for suggestions: 

    {path: "/suggestions/", components: {default: SuggestionsHome}},
    {path: "/suggestions/panel", components: {default: PanelSuggestions}},
    {path: "/suggestions/bug-report", components: {default: BugReports}},
    {path: "/suggestions/general", components: {default: GeneralSuggestions}},
    {path: "/suggestions/planned-changes", components: {default: PlannedChanges}},

        // Path for suggestions admin:

    {path: "/suggestions/admin/general", components: {default: GeneralSuggestionsAdmin}, meta: {requiresAuth: true}},
    {path: "/suggestions/admin/panel", components: {default: PanelSuggestionsAdmin}, meta: {requiresAuth: true}},
    {path: "/suggestions/admin/bugs", components: {default: BugReportsAdmin}, meta: {requiresAuth: true}},

    // Paths for Stats

    {path: "/stats", components: {default: StatsHome}, meta: {requiresAuth: true}},
    {path: "/stats/finance", components: {default: FinancialStats}, meta: {requiresAuth: true}},
    {path: "/stats/finance-2022", components: {default: FinancialStats2022}, meta: {requiresAuth: true}},
    {path: "/stats/finance-2023", components: {default: FinancialStats2023}, meta: {requiresAuth: true}},
    {path: "/stats/finance-2024", components: {default: FinancialStats2024}, meta: {requiresAuth: true}},
    {path: "/stats/yearly", components: {default: YearlyStats}, meta: {requiresAuth: true}},
    {path: "/stats/projects", components: {default: ProjectStats}, meta: {requiresAuth: true}},


    // Paths for goals:

    {path: "/goals/", components: {default: GoalsHome}, meta: {requiresAuth: true}},
    {path: "/goals/all", components: {default: AllGoals}, meta: {requiresAuth: true}},
    {path: "/goals/maintenance", components: {default: MaintenanceGoals}, meta: {requiresAuth: true}},

        // Paths for goals admin:

    {path: "/goals/admin/create", components: {default: AdminCreateGoal}, meta: {requiresAuth: true}},
    {path: "/goals/admin/category-manager", components: {default: GoalsCategoryManager}, meta: {requiresAuth: true}},

    // Paths for online monitoring:

    {path: "/online-monitoring/", components: {default: OnlineMonitoringHome}},

    // Paths for tools

    {path: "/tools/", components: {default: ToolsHome}},
    {path: "/tools/msg/", components: {default: MailSignatureGenerator}},

    // Paths for calendar

    {path: "/calendar/event-categories", components: {default: eventCategories}},
    {path: "/calendar/", components: {default: accaCalendar}},

    // Paths for CRM

    {path: "/crm/", components: {default: CRMHome}},
    {path: "/crm/email-tags", components: {default: EMailTags}},
    {path: "/crm/email-list", components: {default: EMailList}},

    // Paths for OM Platform

    {path: "/om-platform/", components: {default: omHome}},
    {path: "/om-platform/electricity-price", components: {default: electricityPriceTable}},
    {path: "/om-platform/projects", components: {default: omProjects}},
    {path: "/om-platform/project", components: {default: omSingleProject}},
    {path: "/om-platform/project-form", components: {default: omProjectForm}},
    // Paths for super admin:
        // These urls are complex by design.

    {path: "/sadmin-464-451", components: {default: SuperAdminHome}, meta: {requiresAuth: true}},


]


/*

documents/add


*/