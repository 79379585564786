<template>
    <div class="main-container">

      <div class="flex mt-10 w-full row-start-1 col-start-1 col-end-13 items-center">
        
          <div class="border bg-white text-2xl p-3 year-selection rounded-3xl mr-10">
                  <label for="yearSelection">Yıl:</label>
                  <select v-model="selectedYear" class="outline-0" name="yearSelection" id="year">
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                  </select>
              </div>

        <div class="graph-selector cursor-pointer bg-white flex border border-gray-400 px-10 py-3 rounded-3xl">
          <span @click="selectedChart = 'project'" :class="{'underline': selectedChart === 'project'}" class="text-2xl whitespace-nowrap">Proje Sayıları</span>
          <span class="text-2xl whitespace-nowrap mx-5">|</span>
          <span @click="selectedChart = 'units'" :class="{'underline': selectedChart === 'units'}" class="text-2xl whitespace-nowrap">Ünite Sayıları</span>
        </div>

      </div>


      <ac-loader class="col-start-1 col-end-5 bg-white w-full ac-loader" v-if="isLoading"></ac-loader>
      <apexchart
        v-else-if="!isLoading && selectedChart === 'project'"
        class="col-start-1 col-end-13 bg-white rounded-2xl py-10 apexchart rounded-2xl"
        ref="projectChart"
        type="line"
        :options="projectChartOptions"
        :series="filteredProjectSeries"
        height="90%"
    >
      </apexchart>
      <apexchart
        v-else
        class="col-start-1 col-end-13 bg-white rounded-2xl py-10 apexchart rounded-2xl"
        ref="unitChart"
        type="line"
        :options="unitChartOptions"
        :series="filteredUnitSeries"
        height="90%"
    >
      </apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import acLoader from "../../reusable/acLoader.vue"
import axios from 'axios'

export default {
  name: 'ProjectStats',
  data(){ 
    return {
      selectedChart: "project",

      selectedYear: new Date().getFullYear().toString(),
      isLoading: false,

      projectChartOptions: {
        stroke: {
          curve: "straight",
        },
         title: {
            text: "Proje Miktarı",
            align: "center",
            style: {
                fontSize: "16px",
            }
         },
          chart: {

              toolbar: {
                show: false
              }
          },
          dataLabels: {
            enabled: true,
            style: {
                fontSize: "16px",
                colors: ["#40CC90"]
            },
            background: {
              enabled: true,
              borderRadius: 100,
            }
          },
        xaxis: {
          labels: {
            format: "dd MMMM"
          },
          type: "datetime"
        },
        colors: ["#40CC86"],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
      },
      unitChartOptions: {
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 5
        },
         title: {
            text: "Ünite Sayıları",
            align: "center",
            style: {
                fontSize: "16px",
            }
         },
          chart: {
              toolbar: {
                show: false
              }
          },
          dataLabels: {
            enabled: false,
            style: {
                fontSize: "14px",
                colors: ["#40CC86"]
            }
          },
        xaxis: {
          labels: {
            format: "dd MMMM"
          },
          type: "datetime"
        },
        colors: ["#8FBEE8", "#3183D1", ],
          noData: {
            text: "Veriler yükleniyor...",
            align: "center",
          },
      },
        projectData: [],
        outdoorUnitData: [],
        indoorUnitData: []
      }
    },

  computed: {
    filteredProjectSeries(){
      return [{
        name: "Proje Sayıları",
        data: this.projectData.filter((item) => {
        const year = item[0].getFullYear().toString()
        return year === this.selectedYear
      })
      }]
    },

     filteredUnitSeries(){
      return [
        {
        name: "İç Ünite Sayıları",
        data: this.indoorUnitData.filter((item) => {
        const year = item[0].getFullYear().toString()
        return year === this.selectedYear
        })
        },
        {
        name: "Dış Ünite Sayıları",
        data: this.outdoorUnitData.filter((item) => {
        const year = item[0].getFullYear().toString()
        return year === this.selectedYear
        })
        },
      ]
    }
  },

  components:{
    'apexchart': VueApexCharts,
    acLoader,
  },

  methods:{
/* 
    resetChartData(){
      this.projectSeries[0].data = []
      this.unitSeries[0].data = []
      this.unitSeries[1].data = []
    }, */

    async getData(){
      //this.resetChartData()
      this.isLoading = true
      const baseURL = "https://dansuk-dashboard-api.herokuapp.com/projects?"
      axios.get(baseURL)
        .then((response) => {
          const data = response.data.reverse()
          console.log(data)
          for (let value of data){
            const rawDate = value["date"].split("-")
            const date = new Date(rawDate[0], rawDate[1] - 1, rawDate[2])
            const projects = value["projects"]
            const indoor_units = value["indoor_units"]
            const outdoor_units = value["outdoor_units"]
            this.projectData.push([date, projects])
            this.indoorUnitData.push([date, indoor_units])
            this.outdoorUnitData.push([date, outdoor_units])
          }
        })
      this.isLoading = false
    },




    selectGraph(graph){
      if(graph === "units"){
        this.$refs.projectChart.$options = "unitChartOptions"
      }
    }
  },
  created(){
    this.getData()
  },
}
</script>

<style scoped lang="scss">
  
  @import "../../../styles/core/variables.scss";
  @import "../../../styles/core/resets.scss";
  @import "../../../styles/global/background.scss";
  @import "../../../styles/global/helpers.scss";

  select, input{
    outline: none;
  }
</style>
